* {
    font-family: 'Inter', Helvetica, sans-serif;
}

section {
    text-align: center;
}

article {
    display: inline-block;
    text-align: left;
    width: 100%;
    max-width: 768px;
}

article.about {
    width: 100%;
    max-width: 370px;
}

h1, h2, h3, h4, h5, h6 {
    font-size: medium;
}

.date {
    color: darkgray;
}

p {
    color: #202122;
}

a {
    color: #0645AD;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

ul {
    padding: 0;
    list-style-type: none;
}